import { useState } from "react";
import "./banner.css";
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Autoplay, Parallax } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import NavigateIcon from "@components/icons/NavigateIcon";
import useWindowSize from "@hooks/useWindowSize";

interface CarouselComponentProps {
  data: any;
  isSub?: boolean;
}

const CarouselComponent: React.FC<CarouselComponentProps> = ({
  data,
  isSub,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const windowSize = useWindowSize();

  const handleSlideChange = (swiper: SwiperClass) => {
    setCurrentSlide(swiper.realIndex);
  };

  const navNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  }

  const navPrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  }

  return (
    data.length > 0 ?
      <div className="carousel-container relative max-w-[1200px] mx-auto mt-4">
        <div className="indicator absolute flex items-end justify-end w-full h-full">
          <div className="text-sm z-10 py-0.5 px-2 bg-slate-700/40 rounded-xl">
            <span className="font-notokr text-slate-100 mr-0.5">{currentSlide + 1}</span>
            <span className="font-notokr text-slate-200 mr-0.5">/</span>
            <span className="font-notokr text-slate-200">{data.length}</span>
          </div>
        </div>
        {!isSub &&
          <div className="navigator absolute flex items-center justify-between h-full w-full">
            <NavigateIcon size={8} direction="prev" onClick={navPrev} className="max-md:h-5 max-md:w-5 stroke-alco-gray-500" />
            <NavigateIcon size={8} direction="next" onClick={navNext} className="max-md:h-5 max-md:w-5 stroke-alco-gray-500" />
          </div>
        }
        <Swiper
          onSwiper={setSwiper}
          spaceBetween={10}
          slidesPerView={isSub ? 'auto' : 1.05}
          centeredSlides={isSub}
          onSlideChange={handleSlideChange}
          slideToClickedSlide={true}
          speed={600}
          breakpoints={isSub ? undefined : {
            768: {
              slidesPerView: 1.3,
            },
            1024: {
              slidesPerView: 2,
            },
            1440: {
              slidesPerView: 3,
            }
          }}
          loop={true}
          autoplay={{
            delay: data[0].SlideSpeed,
            disableOnInteraction: false,
          }}
          parallax={!isSub}
          modules={[Autoplay, Parallax]}
          className={isSub ? "swiper-sub" : "swiper"}
        >
          {data.map((item: any, index: number) => (
            <SwiperSlide key={index} className={`${isSub ? "img-wrapper-sub" : "img-wrapper"} overflow-hidden rounded-[20px]`}>
              <img data-swiper-parallax-x={windowSize.width >= 1024 ? '0%' : '50%'}
                src={item.imageURL}
                alt={`Carousel slide ${item.order}`}
                className={'object-cover rounded-[20px]'}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      : null);
};

export default CarouselComponent;
