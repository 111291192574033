import React from "react";

interface NavigateIconProps {
  size: number;
  direction: 'next' | 'prev';
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  onClick?: () => void;
  className?: string;
}

const NavigateIcon: React.FC<NavigateIconProps> = ({ size, fill, stroke, strokeWidth, direction, onClick, className }) => {

  return (
    <svg
      className={`w-${size} h-${size} cursor-pointer ${className}`}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      viewBox="0 0 28 48"
      onClick={onClick}
    >
      {direction == 'prev' ?
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.6213 0.87868C27.7929 2.05025 27.7929 3.94975 26.6213 5.12132L7.74264 24L26.6213 42.8787C27.7929 44.0503 27.7929 45.9497 26.6213 47.1213C25.4497 48.2929 23.5503 48.2929 22.3787 47.1213L1.37868 26.1213C0.207107 24.9497 0.207107 23.0503 1.37868 21.8787L22.3787 0.87868C23.5503 -0.292893 25.4497 -0.292893 26.6213 0.87868Z" fill="#D0D0D0">
        </path> :
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.37868 47.1213C0.207106 45.9497 0.207106 44.0503 1.37868 42.8787L20.2574 24L1.37868 5.12132C0.207106 3.94975 0.207106 2.05025 1.37868 0.87868C2.55025 -0.292894 4.44975 -0.292894 5.62132 0.87868L26.6213 21.8787C27.7929 23.0503 27.7929 24.9497 26.6213 26.1213L5.62132 47.1213C4.44975 48.2929 2.55025 48.2929 1.37868 47.1213Z" fill="#D0D0D0">
        </path>
      }
    </svg>
  );
};

export default NavigateIcon;
