import React from "react";

interface FavoriteIconProps {
  size: number;
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  onClick?: () => void;
  className?: string;
}

const FavoriteIcon: React.FC<FavoriteIconProps> = ({ size, fill, stroke, strokeWidth, onClick, className }) => {

  return (
    <svg
      className={`w-${size} h-${size} cursor-pointer ${className}`}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z"
      />
    </svg>
  );
};

export default FavoriteIcon;
