import { UserContext } from '@context/UserContext';
import React, { useContext, useEffect, useState } from "react";
import { useGetLPointBalanceMutation, useRegisterLPointCardMutation } from "../../redux/services/payments";
import "./styles.css";

interface LPointCardFormProps {
  onClose: () => void;
  onBalanceCheck: (cardNumber: string, balance: number) => Promise<void>;
  onRegistrationSuccess: () => void;
}

const LPointCardForm: React.FC<LPointCardFormProps> = ({ onClose, onBalanceCheck, onRegistrationSuccess }) => {
  const [cardNumber, setCardNumber] = useState<string>(""); 
  const [availablePoints, setAvailablePoints] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [getLPointBalance, { isLoading: isBalanceLoading }] = useGetLPointBalanceMutation();
  const [registerLPointCard] = useRegisterLPointCardMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { session_token } = useContext(UserContext);

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage, onClose]);

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ""); 
    if (value.length > 16) return;

    const formattedValue = value
      .replace(/(\d{4})(?=\d)/g, "$1 ")
      .replace(/(\d{4})(\d{4})(\d{4})(\d{0,4})/, "$1 $2 $3 $4");

    setCardNumber(formattedValue);

    if (value.length === 16) {
      setError(null);
    } else {
      setAvailablePoints(null);
      setError(null);
    }
  };

  const handleBalanceCheckWithPopup = async (cardNum: string, balance: number) => {
    try {
      await onBalanceCheck(cardNum, balance);
    } catch (error) {
      console.error('결제 처리 중 오류:', error);
      alert('결제 처리 중 오류가 발생했습니다.');
    }
  };

  const checkBalance = async (cardNum: string) => {
    if (!session_token) {
      console.error("세션 토큰이 없습니다.");
      setError("인증에 실패했습니다. 다시 로그인해주세요.");
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const response = await getLPointBalance({ 
        token: session_token, 
        cardNumber: cardNum 
      }).unwrap();
      
      if (response.code === 200 || response.code === 201) {
        const avlPtValue = response.data?.data?.avlPt;
        if (avlPtValue !== undefined) {
          console.log("L.Point:", avlPtValue);
          setShowSuccessMessage(true);
        } else {
          throw new Error("포인트 정보를 찾을 수 없습니다.");
        }
      } else {
        throw new Error(response.message || "잔액 조회에 실패했습니다.");
      }
    } catch (error: any) {
      console.error("잔액 조회 실패:", error);
      if (error.data?.message) {
        setError(error.data.message);
      } else {
        setError("잔액 조회에 실패했습니다. 다시 시도해 주세요.");
      }
      setAvailablePoints(null);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (cardNumber.replace(/\s/g, '').length !== 16) {
      setError("올바른 카드 번호를 입력해 주세요.");
      return;
    }
    await registerCard(cardNumber.replace(/\s/g, ''));
  };

  const registerCard = async (cardNum: string) => {
    if (!session_token) {
      console.error("세션 토큰이 없습니다.");
      setError("인증에 실패했습니다. 다시 로그인해주세요.");
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const response = await registerLPointCard({
        token: session_token,
        cardNumber: cardNum
      }).unwrap();

      if (response.code === 200 || response.code === 201) {
        console.log("L.Point 카드 등록 성공");
        setIsRegistered(true);
        setShowSuccessMessage(true);
        onRegistrationSuccess();
        setTimeout(() => {
          onClose();
        }, 2000);
      } else if (response.code === 409) {
        setError("이미 등록된 카드입니다.");
        setIsRegistered(true);
        onRegistrationSuccess();
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        throw new Error(response.message || "카드 등록에 실패했습니다.");
      }
    } catch (error: any) {
      console.error("카드 등록 오류:", error);
      
      if (error.data?.code === 400 || error.status === 400) {
        setError("유효하지 않은 카드번호입니다.");
      } else if (error.data?.code === 409 || error.status === 409) {
        setError("이미 등록된 카드입니다.");
        setIsRegistered(true);
      } else if (error.data?.message) {
        setError(error.data.message);
      } else {
        setError("카드 등록에 실패했습니다. 다시 시도해 주세요.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputStyle = {
    fontSize: window.innerWidth >= 768 ? '1.5vw' : '3vw',
    padding: '1.5vw',
    width: '100%',
    textAlign: 'center' as const,
    height: '5vh',
    backgroundColor: '#000000',
    color: 'white',
    border: 'none',
    borderRadius: '1.875px'
  };

  const labelStyle = {
    fontSize: window.innerWidth >= 768 ? '1.25vw' : '2.5vw',
    display: 'block',
    textAlign: 'center' as const
  };

  return (
    <main className="container" style={{ 
      width: '50vw', 
      maxWidth: '450px', 
      position: 'fixed', 
      top: '50%', 
      left: '50%', 
      transform: 'translate(-50%, -50%) perspective(1000px) rotateX(5deg)',
      padding: '0.75vw', 
      backgroundColor: '#009cfc',
      borderRadius: '10px', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '20vh',
      boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.3), 0 8px 10px -6px rgba(0, 0, 0, 0.2)',
      background: 'linear-gradient(145deg, #00a8ff, #0088cc)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(10px)',
      transition: 'all 0.3s ease'
    }}>
      <button 
        onClick={onClose}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'none',
          border: 'none',
          fontSize: '24px',
          cursor: 'pointer',
          color: '#ffffff',
          width: '30px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0',
          lineHeight: '1',
          zIndex: '1000',
        }}
      >
        ✕
      </button>
      <form id="credit-card" onSubmit={handleSubmit} style={{ width: '80%', maxWidth: '225px' }}>
        <div style={{ marginBottom: '3vh' }}>
          <label htmlFor="card-number" style={labelStyle}>카드 번호</label>
          <input
            type="text"
            name="card-number"
            id="card-number"
            maxLength={19}
            placeholder="1234 5678 9101 1121"
            value={cardNumber}
            onChange={handleCardNumberChange}
            required
            style={inputStyle}
          />
          <div style={{ marginTop: '1vh', fontSize: '2vw', color: 'red', textAlign: 'center' }}>
            {isSubmitting && <p></p>}
            {error && <p className="error">{error}</p>}
          </div>
        </div>

        <input 
          type="submit" 
          value={isRegistered ? "카드 등록이 완료되었습니다" : (isSubmitting ? "" : "카드 등록")} 
          id="add" 
          disabled={isSubmitting || isRegistered}
          style={{
            fontSize: '3vw',
            padding: '1.5vw',
            width: '100%',
            height: '5vh',
            backgroundColor: '#009cfc',
            color: 'white',
            border: 'none',
            borderRadius: '1.875px',
            cursor: 'pointer',
            boxShadow: '0 1.5px 2.25px rgba(0, 0, 0, 0.1)',
            transition: 'all 0.3s ease',
            background: 'linear-gradient(145deg, #008ce3, #009cfc)',
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.boxShadow = '0 2.25px 3px rgba(0, 0, 0, 0.15)';
            e.currentTarget.style.transform = 'translateY(-0.75px)';
            e.currentTarget.style.background = '#4CAF50'; 
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.boxShadow = '0 1.5px 2.25px rgba(0, 0, 0, 0.1)';
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.background = 'linear-gradient(145deg, #008ce3, #009cfc)';
          }}
          onMouseDown={(e) => {
            e.currentTarget.style.background = '#45a049'; 
          }}
          onMouseUp={(e) => {
            e.currentTarget.style.background = '#4CAF50'; 
          }}
        />
      </form>
    </main>
  );
};

export default LPointCardForm;